import 'bootstrap'
import $ from 'jquery'
import 'jquery-lazy'
import 'jquery-lazy/plugins/jquery.lazy.picture'
import 'jquery-lazy/plugins/jquery.lazy.iframe'
import { initParallax } from './parallax'
import { initSliders, initDataSliders } from './slider'
import { initPopup } from './popup'
import { initHighlightedSlider } from './dots'
import { initializePriceUpdate } from './updateprice'
import { IconHoverFix } from './iconhoverfix'
import { getCookie } from './cookie'
import { lazyIconDropdown } from './lazyicondropdown'
import { initModalPopup } from './modal_popup'

/* eslint-disable new-cap */

export const oxyan = {
  layout: () => $('.lazy').Lazy(),
  productSlider: () => initSliders("#product_gallery", {loop: false, nav: true, navAsThumbnails: true, autoplay:false, autoHeight:false}),
  oxyanSlider: () => initSliders(".oxyan-carousel", {autoHeight:true, controls:true}),
  highlightSlider: () => initSliders(".dot-slider", {autoHeight:true, controls:true, loop:false, items:1}),
  productCarousel: () => initSliders(".product-carousel", {controls:true, loop:true, items:4, responsive:{991:{items:4}, 0:{items:2}}}),
  IconSlider: () => initSliders(".custom-simple-carousel", {autoHeight:true, controls:true, loop:true, items:6, responsive:{1200:{items:6}, 991:{items:4}, 0:{items:2}}}),
  GalleryPopup: () => initPopup(".popuplink"),
  initPopup,
  initParallax,
  initHighlightedSlider,
  initSliders,
  initializePriceUpdate,
  IconHoverFix,
  lazyIconDropdown,
  getCookie,
  initDataSliders,
  initModalPopup,
}
