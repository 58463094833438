import 'tiny-slider/dist/tiny-slider.css'

function toBool(val) {
  return JSON.parse(val)
}

function _initSlider(sliderNodes, {
  slideBy='page',
  items=1,
  controls=false,
  controlsText=["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
  controlsContainer=false,
  prevButton=false,
  nextButton=false,
  autoplayTimeout=5000,
  loop=true,
  nav=false,
  autoHeight=true,
  navAsThumbnails=false,
  navContainer=".product-thumbnails",
  autoplay=false,
  lazyload=true,
  startIndex=0,
  responsive={},
  ...props
}) {
  return import(

    /* webpackChunkName: "tinyslider" */
    'tiny-slider/src/tiny-slider').then(({ tns }) => {
    sliderNodes.forEach(container => {
      let hasNavContainer = Boolean(container.parentElement.querySelector(navContainer))
      tns({
        container,
        slideBy,
        items: parseInt(items, 10),
        controls: toBool(controls),
        controlsText,
        controlsContainer,
        prevButton: toBool(prevButton),
        nextButton: toBool(prevButton),
        autoplayTimeout: parseInt(autoplayTimeout, 10),
        loop: toBool(loop),
        nav: toBool(nav),
        autoHeight: toBool(autoHeight),
        navAsThumbnails: hasNavContainer ? navAsThumbnails : false,
        navContainer: hasNavContainer ? navContainer : null,
        startIndex: toBool(startIndex),
        autoplay: toBool(autoplay),
        lazyload: toBool(lazyload),
        // copy the responsive dict because it will modified by tns, this code is excuted in a loop!
        responsive: {...responsive},
        ...props,
      })
    })
  })
}

function _initSliders(selector, config) {
  const sliderNodes = document.querySelectorAll(selector)
  if (sliderNodes.length) {
    _initSlider(sliderNodes, config)
  }
}

export function initSliders(selector, config) {
  if (config) {
    return _initSliders(selector, config)
  } else {
    return _initSliders(selector, {})
  }
}

export function initDataSliders() {
  const sliderNodes = document.querySelectorAll('[data-behaviour="tinyslider"]')
  sliderNodes.forEach(el => {
    _initSlider([el], el.dataset)
  })
}
